import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function SocialWidget() {
  return (
    <div className="cs_social_links_wrap">
      <h2>Follow Us</h2>
      <div className="cs_social_links">
        <Link target='_blank' to="https://www.facebook.com/profile.php?id=61562846372363">
          <Icon icon="fa-brands:facebook-f" />
        </Link>
        <Link target='_blank' to="https://www.youtube.com/watch?v=HqkuMSEIYE4">
          <Icon icon="fa-brands:youtube" />
        </Link>
        {/* <Link to="/">
          <Icon icon="fa-brands:linkedin-in" />
        </Link> */}
        <Link target='_blank' to="https://x.com/MyCarity_US">
          <Icon icon="fa-brands:twitter" />
        </Link>
        <Link target='_blank' to="https://www.instagram.com/mycarity">
          <Icon icon="fa-brands:instagram" />
        </Link>
      </div>
    </div>
  );
}

import React from 'react';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import TextWidget from '../Widget/TextWidget';
import { Link } from 'react-router-dom';
const menuDataOne = [
  { title: 'FAQs', href: '../faq' },
  { title: 'Privacy Policy', href: '../policy' },
  { title: 'Terms Of Use', href: '../terms' },
  //{ title: 'Blog', href: '/blog' },
  // { title: 'Departments', href: '/departments' },
  // { title: 'Doctors', href: '/doctors' },
  // { title: 'Timetable', href: '/timetable' },
  // { title: 'Appointment', href: '/appointments' },
  // { title: 'Testimonials', href: '/' },
];
const menuDataTwo = [
  { title: 'About Us', href: '/about' },
  { title: 'Contact Us', href: '/contact' },
  { title: 'Unsubscribe', href: '/unsubscribe' },
];

export default function Footer() {
  return (
    <footer className="cs_footer cs_style_1 cs_heading_color">
      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: 'url(/images/footer_bg_1.svg)' }}
      >
        <div
          className="cs_footer_brand"
          style={{ backgroundImage: 'url(/images/footer_logo_bg.svg)' }}
        >
          <img
            src="/images/logo_icon.svg"
            alt="MyCarity"
            className="cs_footer_brand_icon"
          />
          <h2 className="cs_footer_brand_text">MyCarity</h2>
        </div>
      </div>
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <TextWidget text="MyCarity Your Complete <br />In-Home Care Solutions" />
                <div style={{display:'flex', flexDirection:'row', gap:10, marginBottom:10 }}>
                  <Link target='_blank' to={'https://play.google.com/store/apps/details?id=com.mycarity'}>
                      <img  src="/images/GooglePlay.png" alt="Google Play" width={150}/>
                  </Link>
                  <Link target='_blank' to={'https://apps.apple.com/us/app/mycarity/id6473648847'}>
                      <img  src="/images/AppStore.png" alt="App Store" width={150}/>
                  </Link>
                </div>
                <ContactInfoWidget />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataOne} />
              </div>
              
            </div>
            
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataTwo} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <Newsletter
                  title="Be Our Subscribers"
                  subTitle="To get the latest news about in-home care from our experts"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="cs_footer_bottom cs_accent_bg">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <SocialWidget />
          
            <div className="cs_copyright">
              Copyright © 2023 MyCarity Services. All rights reserved.
            </div>
            
          </div>
        </div>
      </div>
    </footer>
  );
}

import React from 'react';
import Hero from '../Hero';
import BrandsSection from '../Section/BrandsSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import WorkingProcess from '../Section/WorkingProcess';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
import BlogSection from '../Section/BlogSection';
import AppointmentSection from '../Section/AppointmentSection';
import FaqSection from '../Section/FaqSection';
import DepartmentSection from '../Section/DepartmentSection';
import { pageTitle } from '../../helpers/PageTitle';
import TestimonialSectionStyle3 from '../Section/TestimonialSection/TestimonialSectionStyle3';

const faqData = [
  
  {
    title: 'How do I schedule an appointment with a Provider?',
    content:
      'Scheduling an appointment with a provider on MyCarity is quick, efficient, and secure. Simply visit the provider’s profile and click the Contact Provider button. You’ll be prompted to select the type of service you’re inquiring about and complete a form with the necessary details, such as the preferred date, time, and task requirements. Once you send the inquiry, you’ll be redirected to the Messaging System, where you can communicate directly with the provider to discuss any additional details before confirming the booking schedule. MyCarity’s streamlined process ensures that scheduling care is secure, simple, personalized, and hassle-free. Connecting clients and providers directly without any third parties or agencies involved.',
  },
  {
    title: 'Do you accept insurance?',
    content:
      'Currently, MyCarity does not directly accept insurance for payments. However, many clients choose to use their insurance reimbursement options after paying for services. We recommend checking with your insurance provider to see if they cover in-home caregiving services and if they can reimburse you for any payments made through MyCarity. For your convenience, MyCarity provides clear, transparent invoices that are free of charge and can be submitted to your insurance company for reimbursement, depending on your policy.',
  },
  {
    title: 'What services does MyCarity offer?',
    content:
      'MyCarity is a caregiving platform that revolutionizes in-home care with its Uberized care model, connecting clients to trusted caregivers within a 100-mile radius. Whether you need personal care, companionship, household assistance, specialized care, transportation, or even pet care, MyCarity ensures your needs are met with professionalism and compassion. The platform offers features designed for safety and convenience. Clients can easily book and manage appointments, post caregiving jobs for immediate needs, and communicate securely with caregivers through a messaging system that captures agreements and closes after bookings for added privacy. Profiles showcase caregiver credentials and background check details, fostering trust and confidence. MyCarity’s automated invoicing and real-time reminders make caregiving stress-free for both clients and providers. With advanced matching, robust safety measures, and user-friendly tools, MyCarity is the ultimate choice for reliable, high-quality care delivered with compassion and convenience.',
  },
  {
    title: 'Where can I find the lists of qualified Providers?',
    content:
      'You can find a list of vetted, verified, and qualified providers directly on your home screen. The providers are displayed based on your location and can be filtered to show caregivers within a 100-mile radius of your area. All providers listed on MyCarity have undergone rigorous background checks and are fully verified to ensure they meet the highest standards of care. You can view their profiles, which include essential details like qualifications, care experience, rates, and availability.',
  },
  {
    title: 'How do I request a for a specific task to a Provider?',
    content:
      'To request a specific task from a provider on MyCarity, simply visit the provider’s profile and click the Contact Provider button. You’ll be prompted to select the type of service you’re inquiring about and fill out a form with all the necessary details, including the specific task you need assistance with, preferred date, and time. Once you’ve submitted the request, you’ll be directed to the Messaging System, where you can communicate directly with the provider to confirm the task and finalize the details before confirming and scheduling the service. Once the booking is confirmed, the specific tasks will be added to the Schedule Manager. Providers will update the task with notes of completion and can also indicate what has been done during the service. This feature allows for clear communication and ensures that every task is completed to your satisfaction, with full transparency for both clients and provider',
  },
];
const blogData = [
  {
    title: 'The Benefits of Mindfulness Meditation for Stress and Anxiety',
    thumbUrl: 'images/home_1/post_1.jpeg',
    date: 'May 1, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'Healthy Eating on a Budget: Tips and Strategies',
    thumbUrl: 'images/home_1/post_2.jpeg',
    date: 'May 4, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
  {
    title: 'The Importance of Regular Cancer Screenings and Early Detection',
    thumbUrl: 'images/home_1/post_3.jpeg',
    date: 'May 1, 2023',
    btnText: 'Learn More',
    href: '/blog/blog-details',
    socialShare: true,
  },
];

const departmentData = [
  {
    title: 'Emergency Department',
    iconUrl: 'images/home_1/department_icon_1.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Pediatric Departement',
    iconUrl: 'images/home_1/department_icon_2.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Gynecology Department',
    iconUrl: 'images/home_1/department_icon_3.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Cardiology Department',
    iconUrl: 'images/home_1/department_icon_4.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Neurology Department',
    iconUrl: 'images/home_1/department_icon_5.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Psychiatry Department',
    iconUrl: 'images/home_1/department_icon_6.svg',
    href: '/departments/department-details',
  },
];

const workingProcessData = [
  {
    title: 'Curated list of Providers',
    subTitle:
      'Our <b>Curated List of Providers</b> displays caregivers within a 100-mile radius of your location. Each provider is vetted and verified, ensuring you can easily find care options nearby. With just a few taps, you can explore profiles, compare services, and connect with a provider who fits your unique care needs.',
    iconUrl: '/images/home_1/how_1.png',
    number: '01',
  },
  {
    title: 'Check Provider Profile',
    subTitle:
      'The Profiles feature provides essential details, including credentials, care experiences, background check status, provider location map, and client reviews, to help you make informed decisions. Clients can easily connect with a provider directly through the <b>"Contact Provider"</b> button on their profile.',
    iconUrl: '/images/home_1/how_2.png',
    number: '02',
  },
  {
    title: 'Fill Provider Inquiry Form',
    subTitle:
      'Use this form to connect directly with vetted providers on MyCarity. You can specify your preferred date, time, asking rate, and detailed <b>care task requirements</b>, ensuring a tailored approach to your unique needs. It is a simple and efficient way to initiate care and find the right provider for you.',
    iconUrl: '/images/home_1/how_3.png',
    number: '03',
  },
  {
    title: 'Connect with Provider',
    subTitle:
      'MyCarity’s <b>Messaging System</b> enables seamless communication between clients and providers. After an inquiry is sent, both parties can use the messaging feature to discuss care details, scheduling, and other important needs. ',
    iconUrl: '/images/home_1/how_4.png',
    number: '04',
  },
  {
    title: 'Agree and Book',
    subTitle:
      'MyCarity’s <b>Agreement Aggregator</b> simplifies the process of finalizing care arrangements between clients and providers. Once an agreement is reached, the booking is confirmed, and the messaging system remains active for any follow-up discussions until the booking is completed or closed, ensuring a seamless and organized experience."',
    iconUrl: '/images/home_1/how_5.png',
    number: '05',
  },
  {
    title: 'Booked Provider Inquiry',
    subTitle:
      'Once both parties agree, the Booked Provider Inquiry is automatically added to the Schedule Manager under <b>"Upcoming Schedule"</b>, keeping track of your confirmed appointments in one convenient location.',
    iconUrl: '/images/home_1/how_6.png',
    number: '06',
  },
];

export default function Home() {
  pageTitle('Home');
  return (
    <>
      <Hero
        title="Connecting Care, Simplifying Lives"
        subTitle="An uberized care, dedicated to delivering compassionate, personalized in-home support that empowers you to thrive in the comfort of your own home."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img.png"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/jgC_PIT-6Mw?si=YCEjRkX6kVQPMbdf"
        infoList={[
          {
            title: 'Contact Us',
            subTitle: '+1 (877)-346-9227',
            iconUrl: '/images/icons/pin.svg',
            //iconUrl: '/images/contact/icon_1.svg',
          },
          // {
          //   title: 'Soldotna',
          //   subTitle: '+1 907 416 6767',
          //   iconUrl: '/images/icons/pin.svg',
          //   //iconUrl: '/images/icons/ambulance.svg',
          // },
          // {
          //   title: 'Fairbanks',
          //   subTitle: '+1 907-336-2273',
          //   iconUrl: '/images/icons/pin.svg',
          // },
        ]}
        
        btnText="Get Started with Android"
        btnUrl="https://play.google.com/store/apps/details?id=com.mycarity"
        btnText2="Get Started with iOS"
        btnUrl2="https://apps.apple.com/us/app/mycarity/id6473648847"
      />
      {/* Start Departments Section */}
      {/* <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Skills Category"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section> */}

      {/* End Departments Section */}

      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <WorkingProcess
          sectionTitle="How to Directly Book Provider"
          sectionTitleUp=""
          sectionTitleDown=""
          sectionSubTitle=""
          data={workingProcessData}
        />
      </Section>
  
    
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let Your Loved Ones Take a Backseat!"
          subTitle="Find the best caregivers in your area! Book expert, local caregivers instantly!"
        />
      </Section>
      {/* End Banner Section */}
        {/* Start Testimonial */}
        <Section
        topMd={105}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients on our App"
        />
      </Section>
    
      {/* End Testimonial */}
      {/* Start Blog Section */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section> */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      {/* <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section> */}
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      {/* <Section topMd={100} topLg={145} topXl={105} bottomMd={30}>
        <FaqSection
          data={faqData}
          sectionTitle="Frequently Asked"
          sectionTitleUp="What People"
        />
      </Section> */}
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      {/* <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section> */}
      {/* End Brand Section */}
    </>
  );
}

import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import Section from '../Section';
import PricingSection from '../Section/PricingSection';
import FaqSectionStyle4 from '../Section/FaqSection/FaqSectionStyle4';
import BannerSectionStyle10 from '../Section/BannerSection/BannerSectionStyle10';
const pricingData = [
  {
    title: 'Monthly',
    subTitle:
      'All subscriptions will automatically renew at the end of each billing cycle unless canceled prior to the renewal date. If you cancel your subscription, you will continue to have access to the paid benefits until the end of your current billing period. No refunds or credits will be issued for partial billing periods. Applicable sales taxes may be added to your subscription fee.',
    price: '$29',
    period: '/month',
    featureList: [
      'Job Posting Tool',
      'Task Management System',
      'Invoice Payment System',
      'Communication Tool',
      'Agreement Aggregator',
      'Access to your nearest providers',
      'Find care for all your household needs',
      'No commitment, cancel anytime',
    ],
    btnText: 'Get Started with Android',
    btnUrl: 'https://play.google.com/store/apps/details?id=com.mycarity',
    btnText2: 'Get Started with iOS',
    btnUrl2: 'https://apps.apple.com/us/app/mycarity/id6473648847',
    popular: true,
  },
  {
    title: 'Quarterly',
    subTitle:
      'All subscriptions will automatically renew at the end of each billing cycle unless canceled prior to the renewal date. If you cancel your subscription, you will continue to have access to the paid benefits until the end of your current billing period. No refunds or credits will be issued for partial billing periods. Applicable sales taxes may be added to your subscription fee.',
    price: '$24',
    period: '/month',
    featureList: [
      'Job Posting Tool',
      'Task Management System',
      'Invoice Payment System',
      'Communication Tool',
      'Agreement Aggregator',
      'Access to your nearest providers',
      'Find care for all your household needs',
      'No commitment, cancel anytime',
    ],
    btnText: 'Get Started with Android',
    btnUrl: 'https://play.google.com/store/apps/details?id=com.mycarity',
    btnText2: 'Get Started with iOS',
    btnUrl2: 'https://apps.apple.com/us/app/mycarity/id6473648847',
    popular: false,
  },
  {
    title: 'Yearly',
    subTitle:
      'All subscriptions will automatically renew at the end of each billing cycle unless canceled prior to the renewal date. If you cancel your subscription, you will continue to have access to the paid benefits until the end of your current billing period. No refunds or credits will be issued for partial billing periods. Applicable sales taxes may be added to your subscription fee.',
    price: '$12',
    period: '/month',
    featureList: [
      'Job Posting Tool',
      'Task Management System',
      'Invoice Payment System',
      'Communication Tool',
      'Agreement Aggregator',
      'Access to your nearest providers',
      'Find care for all your household needs',
      'No commitment, cancel anytime',
    ],
    btnText: 'Get Started with Android',
    btnUrl: 'https://play.google.com/store/apps/details?id=com.mycarity',
    btnText2: 'Get Started with iOS',
    btnUrl2: 'https://apps.apple.com/us/app/mycarity/id6473648847',
    popular: false,
  },
  // {
  //   title: 'Dental Health Plan',
  //   subTitle:
  //     'Smile with confidence. Enjoy regular dental check-ups, emergency coverage, and exclusive discounts on dental procedures.',
  //   price: '$79',
  //   period: '/month',
  //   featureList: [
  //     'Dental Check-ups',
  //     'Procedure Discounts',
  //     'Emergency Coverage',
  //     'Oral Health Advice',
  //   ],
  //   btnText: 'Get Started',
  //   btnUrl: '/contact',
  //   popular: false,
  // },
  // {
  //   title: `Women's Health Plan`,
  //   subTitle: `Comprehensive women's health services. Receive expert gynecological care, family planning support, and prenatal/postnatal.`,
  //   price: '$169',
  //   period: '/month',
  //   featureList: [
  //     `Women's Health Services`,
  //     'Gynecological Care',
  //     'Fall Prevention Programs',
  //     'Family Planning',
  //     'Prenatal & Postnatal Support',
  //   ],
  //   btnText: 'Get Started',
  //   btnUrl: '/contact',
  //   popular: false,
  // },
  // {
  //   title: 'Sports & Fitness Plan',
  //   subTitle:
  //     'Optimized for athletes. Access sports injury assessments, physiotherapy, and expert advice for peak performance and recovery.',
  //   price: '$119',
  //   period: '/month',
  //   featureList: [
  //     'Sports Injury Assessments',
  //     'Physiotherapy Sessions',
  //     'Sports Medicine Experts',
  //     'Fitness Support',
  //   ],
  //   btnText: 'Get Started',
  //   btnUrl: '/contact',
  //   popular: false,
  // },
];
const faqData = [
 {
    title: 'How do I schedule an appointment with a Provider?',
    content:
      'Scheduling an appointment with a provider on MyCarity is quick, efficient, and secure. Simply visit the provider’s profile and click the Contact Provider button. You’ll be prompted to select the type of service you’re inquiring about and complete a form with the necessary details, such as the preferred date, time, and task requirements. Once you send the inquiry, you’ll be redirected to the Messaging System, where you can communicate directly with the provider to discuss any additional details before confirming the booking schedule. MyCarity’s streamlined process ensures that scheduling care is secure, simple, personalized, and hassle-free. Connecting clients and providers directly without any third parties or agencies involved.',
  },
  {
    title: 'Do you accept insurance?',
    content:
      'Currently, MyCarity does not directly accept insurance for payments. However, many clients choose to use their insurance reimbursement options after paying for services. We recommend checking with your insurance provider to see if they cover in-home caregiving services and if they can reimburse you for any payments made through MyCarity. For your convenience, MyCarity provides clear, transparent invoices that are free of charge and can be submitted to your insurance company for reimbursement, depending on your policy.',
  },
  {
    title: 'What services does MyCarity offer?',
    content:
      'MyCarity is a caregiving platform that revolutionizes in-home care with its Uberized care model, connecting clients to trusted caregivers within a 100-mile radius. Whether you need personal care, companionship, household assistance, specialized care, transportation, or even pet care, MyCarity ensures your needs are met with professionalism and compassion. The platform offers features designed for safety and convenience. Clients can easily book and manage appointments, post caregiving jobs for immediate needs, and communicate securely with caregivers through a messaging system that captures agreements and closes after bookings for added privacy. Profiles showcase caregiver credentials and background check details, fostering trust and confidence. MyCarity’s automated invoicing and real-time reminders make caregiving stress-free for both clients and providers. With advanced matching, robust safety measures, and user-friendly tools, MyCarity is the ultimate choice for reliable, high-quality care delivered with compassion and convenience.',
  },
  {
    title: 'Where can I find the lists of qualified Providers?',
    content:
      'You can find a list of vetted, verified, and qualified providers directly on your home screen. The providers are displayed based on your location and can be filtered to show caregivers within a 100-mile radius of your area. All providers listed on MyCarity have undergone rigorous background checks and are fully verified to ensure they meet the highest standards of care. You can view their profiles, which include essential details like qualifications, care experience, rates, and availability.',
  },
  {
    title: 'How do I request a for a specific task to a Provider?',
    content:
      'To request a specific task from a provider on MyCarity, simply visit the provider’s profile and click the Contact Provider button. You’ll be prompted to select the type of service you’re inquiring about and fill out a form with all the necessary details, including the specific task you need assistance with, preferred date, and time. Once you’ve submitted the request, you’ll be directed to the Messaging System, where you can communicate directly with the provider to confirm the task and finalize the details before confirming and scheduling the service. Once the booking is confirmed, the specific tasks will be added to the Schedule Manager. Providers will update the task with notes of completion and can also indicate what has been done during the service. This feature allows for clear communication and ensures that every task is completed to your satisfaction, with full transparency for both clients and provider',
  },
];
export default function PricingPlan() {
  pageTitle('Subscription Plan');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pricing_plan/banner_bg.svg"
        imgUrl="/images/pricing_plan/banner_img.png"
        title="Find the flexible Subscription for You"
        subTitle="Explore Our Subscription Options and Start Your Journey to Better In-Home Care"
        btnText="Get Started with Android"
        btnUrl="https://play.google.com/store/apps/details?id=com.mycarity"
        btnText2="Get Started with iOS"
        btnUrl2="https://apps.apple.com/us/app/mycarity/id6473648847"
        
      />
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomMd={10}
        bottomLg={10}
        bottomXl={10}
      >
        <PricingSection
          sectionTitle="Choose Your In-Home Care <br />Subscription Plan"
          data={pricingData}
        />
      </Section>
      {/* <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
        className="cs_gray_bg_1"
      >
        <FaqSectionStyle4
          sectionTitle="Frequently Asked <br />Questions"
          data={faqData}
          faqImgUrl="/images/home_4/faq_img.jpeg"
          spiningImgUrl="/images/home_1/about_mini.svg"
        />
      </Section> */}
      <Section
        topMd={20}
        topLg={20}
        topXl={20}
        className="cs_footer_margin_0"
      >
        <BannerSectionStyle10
          imgUrl="/images/pricing_plan/banner_img_2.png"
          title="Choose Your Subscription Today and Invest in a Better In-Home Care!"
        />
      </Section>
    </>
  );
}

import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

export default function BannerSectionStyle10({ imgUrl, title }) {
  return (
    <div className="container">
      <div className="cs_banner cs_style_9 cs_type_1 cs_white_bg cs_radius_30" >
        <div className="cs_banner_img" style={{height:600, }} >
          <img src={imgUrl} alt="MyCarity: Care Connect"style={{width:800, height:550,}}  />
        </div>
        <h2 className="cs_banner_title cs_fs_72 mb-0" style={{marginTop:-50, marginRight:-20,}}>{parse(title)}</h2>
        <div style={{display:'flex', flexDirection:'row', gap:10, marginTop:10 }}>
          <Link target='_blank' to={'https://play.google.com/store/apps/details?id=com.mycarity'}>
              <img  src="/images/GooglePlay.png" alt="Google Play" width={250}/>
          </Link>
          <Link target='_blank' to={'https://apps.apple.com/us/app/mycarity/id6473648847'}>
              <img  src="/images/AppStore.png" alt="App Store" width={250}/>
          </Link>
        </div>
        
      </div>
    </div>
  );
}

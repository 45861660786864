import { Icon } from '@iconify/react';
import React from 'react';

export default function ContactInfoWidget() {
  return (
    <ul className="cs_contact_widget">
      <li>
        <i className="cs_accent_bg">
        <Icon icon="bi:envelope" />
        </i>
        <a href="mailto:support@mycarity.us" target="_blank"> support@mycarity.us</a>
      </li>
      {/* <li>
        <i className="cs_accent_bg">
          <Icon icon="bi:envelope" />
        </i>
        info@mycarity.us
      </li> */}
      <li>
        <i className="cs_accent_bg">
          <Icon icon="fluent:call-24-regular" />
        </i>
        +1 (877)-346-9227
      </li>
    </ul>
  );
}

import React from 'react';
import parse from 'html-react-parser';

export default function IconBoxStyle3({ title, subTitle, iconUrl, iconUrl2, number }) {
  const { innerWidth: width, innerHeight: height } = window;
  return (
    <div className="cs_iconbox cs_style_3">
      <div className="cs_iconbox_left">
        <div className="cs_iconbox_icon cs_center rounded-circle" style={{width:250, marginTop:130, marginBottom:50, backgroundColor:'transparent'}}>
          <img src={iconUrl} alt="Icon" style={{}} />
        
        </div>
      </div>
      <div className="cs_iconbox_right" style={{marginTop:50, }}>
        <div style={{backgroundColor:'white', borderRadius:25, padding:15}}>
          <h4 className="cs_iconbox_number" style={{color:'darkturquoise', }}>{number}</h4>
          <h2 className="cs_iconbox_title cs_fs_32 cs_semibold">
            {parse(title)}
          </h2>
          {
            (width<500)
            ?<p className="cs_iconbox_subtitle m-0"  style={{display: 'none'}}>{parse(subTitle)}</p> 
            :<p className="cs_iconbox_subtitle m-0"  >{parse(subTitle)}</p>
          }
          {/* <p className="cs_iconbox_subtitle m-0"  style={{minWidth: 600, display: 'none'}}>{parse(subTitle)}</p> */}
        </div>  
      </div>
    </div>
  );
}

import React, { useState, useEffect }  from 'react';
import parse from 'html-react-parser';
import Button from '../../Button';
import Spacing from '../../Spacing';

export default function ({
  bgUrl,
  imgUrl,
  title,
  subTitle,
  btnText,
  btnUrl,
  btnText2,
  btnUrl2,
}) {
  const [animate, setAnimate] = useState(false);

    useEffect(() => {
        // Trigger the animation on component load
        const timer = setTimeout(() => setAnimate(true), 100); // Delay to ensure smooth rendering
        return () => clearTimeout(timer); // Cleanup in case the component unmounts
    }, []);
  return (
    <section
      className="cs_banner cs_style_3 cs_bg_filed"
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <div className="cs_banner_img" style={{width:1000, marginLeft:-350, }}>
        <img src={imgUrl} alt="Banner" className="cs_main_banner_img" 
          style={{
          marginBottom:30, 
          transition: 'transform 0.7s ease-in-out, opacity 0.7s ease-in-out',
          transform: animate ? 'translateX(80px)' : 'translateX(-10px)',
          opacity: animate ? 1 : 0, // Fade-in effect
        }}
        />
      </div>
      <div className="container">
        <div className="cs_banner_text">
          <h2 className="cs_banner_title cs_fs_72">{parse(title)}</h2>
          <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
            {parse(subTitle)}
          </p>
          {btnText && (
            <>
              <Spacing md="25" lg="25" xl="25" />
              <Button btnUrl={btnUrl} btnText={btnText} />
            </>
          )}
          {btnText2 && (
            <>
              <Spacing md="25" lg="25" xl="25" />
              <Button btnUrl2={btnUrl2} btnText2={btnText2} />
            </>
          )}
        </div>
      </div>
    </section>
  );
}

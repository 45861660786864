import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';

export default function BannerSectionStyle5({
  bgUrl,
  imgUrl,
  title,
  subTitle,
}) {
  const [animate, setAnimate] = useState(false);

    useEffect(() => {
        // Trigger the animation on component load
        const timer = setTimeout(() => setAnimate(true), 100); // Delay to ensure smooth rendering
        return () => clearTimeout(timer); // Cleanup in case the component unmounts
    }, []);
  return (
    <section
      className="cs_banner cs_style_5 cs_bg_filed"
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <div className="cs_banner_img" style={{height:800, }}>
        <img src={imgUrl} alt="Banner" 
        style={{
          width:800, 
          height:800, 
          marginBottom:-140, 
          transition: 'transform 0.7s ease-in-out, opacity 0.7s ease-in-out',
          transform: animate ? 'translateY(-10px)' : 'translateY(300px)',
          opacity: animate ? 1 : 0, // Fade-in effect
        }}
        />
      </div>
      <div className="container">
        <div className="cs_banner_text">
          <h2 className="cs_banner_title cs_fs_72">{parse(title)}</h2>
          <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
            {parse(subTitle)}
          </p>
        </div>
      </div>
    </section>
  );
}
